<style scoped lang="scss">
/deep/ .category-edit-el-dialog {
    max-width: 820px;
    .el-cascader {
        width: 100%;
    }
}
</style>

<template>
    <el-dialog title="使用者分类编辑" custom-class="c-el-dialog category-edit-el-dialog" top="5vh" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
        <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="100px" size="small">
            <el-row :gutter="10">
                <el-col :xs="24">
                    <el-form-item label="父级分类">
                        <el-cascader :ref="refCascader" popper-class="c-no-cascader-radio" v-model="dialogData.parentId" :options="categoryActiveList" placeholder="如为第一级分类则不选择" 
                            :props="defaultProps" filterable clearable @change="closeCascader"></el-cascader>
                    </el-form-item>
                </el-col>
                <el-col :xs="24">
                    <el-form-item label="分类名称" prop="name">
                        <el-input v-model="dialogData.name" placeholder="请输入使用者分类名称"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" v-show="false">
                    <el-form-item label="排序">
                        <el-input type="number" :step="1" :min="0" v-model="dialogData.sort" placeholder="请输入大于等于0的整数"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="small" @click="_close()">取消</el-button>
            <el-button size="small" type="primary" @click="_save()">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import * as funInfomation from "@/api/information"
import configMixins from "@/mixins/config"
export default {
    name: "pageAssetsUserTypeEdit",
    mixins: [configMixins],
    data() {
        return {
            refCascader: "refAssetsUserTypeEditCascader",
            showDialog: false,
            dialogData: {},
            defaultDialogData: {
                id: 0,
                parentId: null,
                name: "",
                sort: 0
            },
            dialogType: "",
            formRefName: "refAssetsUserTypeEdit",
            formRules: {
                name: [{ required: true, message: '使用者分类名不能为空', trigger: 'blur'}]
            },
            categoryActiveList: [],
            defaultProps: {
                value: "id",
                children: "children",
                label: "name",
                expandTrigger: "hover",
                checkStrictly: true,
                emitPath: false
            }
        }
    },
    methods: {
        open(row) {
            this.AllInit()
            this.dialogData = JSON.parse(JSON.stringify(this.defaultDialogData))
            if (row) {
                this.dialogType = this.createOrUpdate[1]
                // 编辑
                this.dialogData = JSON.parse(JSON.stringify(row))
            } else {
                this.dialogType = this.createOrUpdate[0]
            }
            this.showDialog = true
        },
        AllInit(){
            window.$common.fullLoading()
            funInfomation.getAssetUserCategory().then(res => {
                window.$common.closeFullLoading()
                this.categoryActiveList = this.parseCategoryTree(res)
            })
        },
        parseCategoryTree(datas) {
            let trees = []
            for(let i in datas) {
                let temp = datas[i]
                if (datas[i].children && datas[i].children.length > 0) {
                    temp.children = this.parseCategoryTree(datas[i].children)
                } else {
                    delete temp.children
                }
                trees.push(temp)
            }
            return trees
        },
        _save() {
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    let commitData = JSON.parse(JSON.stringify(this.dialogData))
                    commitData.sort = commitData.sort <= 0 ? 0 : commitData.sort
                    window.$common.fullLoading()
                    if (this.dialogType == this.createOrUpdate[0]) {
                        // 创建
                        funInfomation.setAssetUserCategory(commitData).then((res) => {
                            this.commonSuccess("操作成功!", res)
                        })
                    } else {
                        // 修改
                        funInfomation.updateAssetUserCategory(commitData).then((res) => {
                            this.commonSuccess("操作成功!", res)
                        })
                    }
                } else {
                    return false
                }
            })
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        closeCascader() {
            this.$refs[this.refCascader].dropDownVisible = false
        },
        commonSuccess(val, data) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                this._close()
                window.$common.closeFullLoading()
                this.refreshData(data)
            })
        },
        refreshData(data) {
            this.$emit("refreshData", data)
        }
    }
}
</script>